<template>
<!-- organization -->
  <div v-if="type === 'organization'" class="data_map_card" :class="[type, color, activeColor, { active: isActive }]" @click="$emit('click')">
    <!-- カード -->
    <div class="border">
      <div v-if="isActive" class="tri">
        <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.5 6.13397C11.1667 6.51888 11.1667 7.48112 10.5 7.86602L1.5 13.0622C0.833332 13.4471 3.43203e-07 12.966 3.76852e-07 12.1962L8.31114e-07 1.80385C8.64763e-07 1.03405 0.833334 0.552922 1.5 0.937822L10.5 6.13397Z" fill="url(#paint0_linear_6839_59921)"/>
        <defs>
        <linearGradient id="paint0_linear_6839_59921" x1="-4" y1="-6.52941" x2="0.00901284" y2="19.0444" gradientUnits="userSpaceOnUse">
        <stop :stop-color="getColor(color).startColor"/>
        <stop offset="1" :stop-color="getColor(color).stopColor"/>
        </linearGradient>
        </defs>
        </svg>
      </div>
      <div class="detail">
        {{item.name}}
      </div>
    </div>
    <!-- SVG後ろ -->
    <div v-if="isActive && isNextItems" class="ellipse">
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="6" cy="6" r="6" fill="url(#paint0_linear_7808_66249)"/>
        <defs>
        <linearGradient id="paint0_linear_7808_66249" x1="-4.14706" y1="12" x2="15.0333" y2="8.99324" gradientUnits="userSpaceOnUse">
        <stop :stop-color="isActive && nextId ? getColor(color).startColor : '#BFBFCD'"/>
        <stop offset="1" :stop-color="isActive && nextId ? getColor(color).stopColor : '#BFBFCD'"/>
        </linearGradient>
        </defs>
      </svg>
    </div>
  </div>

  <!-- organization以下 -->
  <div v-else class="data_map_card _else" :class="[type, color, activeColor, { active: isActive }]" @click="click" ref="item" @mouseover="mouseover" @mouseleave="mouseleave">
    <!-- SVG前 SVGの高さは、親の高さと自分の高さを計算 -->
    <div class="line" v-if="lineHeight">
      <svg width="42" :height="22 + lineHeight" :viewBox="`0 0 42 ${22 + lineHeight}`" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path :d="`M1 1H11.5269C16.9459 1 21.3388 5.39295 21.3388 10.8119V10.8119V27.7416V${9 + lineHeight}V${9 + lineHeight}C21.3388 ${16 + lineHeight} 26.4448 ${20 + lineHeight} 32.7433 ${20 + lineHeight}H41`" :stroke="`url(#paint0_linear_7808_66250_${index}_${item.id})`" stroke-width="2" stroke-linecap="round"/>
        <defs>
        <linearGradient :id="`paint0_linear_7808_66250_${index}_${item.id}`" x1="-12.8235" :y1="`${20 + lineHeight}`" x2="52.5537" :y2="`${17 + lineHeight}`" gradientUnits="userSpaceOnUse">
        <stop :stop-color="isActive ? getColor(activeColor).startColor : '#BFBFCD'"/>
        <stop offset="1" :stop-color="isActive ? getColor(activeColor).stopColor : '#BFBFCD'"/>
        </linearGradient>
        </defs>
      </svg>
    </div>
    <div class="ellipse">
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="6" cy="6" r="6" :fill="`url(#paint0_linear_7808_66254_${index}_${item.id})`"/>
        <defs>
        <linearGradient :id="`paint0_linear_7808_66254_${index}_${item.id}`" x1="-4.14706" y1="12" x2="15.0333" y2="8.99324" gradientUnits="userSpaceOnUse">
        <stop :stop-color="isActive ? getColor(activeColor).startColor : '#BFBFCD'"/>
        <stop offset="1" :stop-color="isActive ? getColor(activeColor).stopColor : '#BFBFCD'"/>
        </linearGradient>
        </defs>
      </svg>
    </div>
    <!-- カード -->
    <div class="border">
      <div class="detail">
        <div v-if="item.status || item.status === 0" class="head">
          <TwTradingStatusIcon v-if="type === 'trading' || type === 'flow'" :statusCode="item.status" />
          <TwStatusIcon v-else :statusCode="item.status" :approval="true" />
          <div class="link" v-if="item.transitionFlag || tradingFlow.transitionFlag" @click.stop="link">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.3333 2H9.83331" stroke="#BFBFCD" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M14.3333 2V6.5" stroke="#BFBFCD" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M8.33331 8L13.5833 2.75" stroke="#BFBFCD" stroke-width="1.25" stroke-linecap="round"/>
              <path d="M6.83331 3.5H2.33331V14H12.8333V9.5" stroke="#BFBFCD" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
        <div v-if="type === 'entity'" class="name">
          <TwEntityIcon :entityName="getEntity.entityId" :popover="false" :color="hoverColor" size="large" style="margin-right:4px" />
          {{$t(`Entity.${getEntity.nameEn}`)}}
        </div>
        <div v-else-if="type === 'process'" class="name">{{getProcessName}}</div>
        <div v-else class="name">{{item.name}}</div>
      </div>
    </div>
    <!-- SVG後ろ -->
    <div v-if="isActive && isNextItems && type !== 'process'" class="ellipse _front">
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="6" cy="6" r="6" :fill="`url(#paint0_linear_7808_66251_${index}_${item.id})`"/>
        <defs>
        <linearGradient :id="`paint0_linear_7808_66251_${index}_${item.id}`" x1="-4.14706" y1="12" x2="15.0333" y2="8.99324" gradientUnits="userSpaceOnUse">
         <stop :stop-color="isActive && nextId ? getColor(activeColor).startColor : '#BFBFCD'"/>
        <stop offset="1" :stop-color="isActive && nextId ? getColor(activeColor).stopColor : '#BFBFCD'"/>
        </linearGradient>
        </defs>
      </svg>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
// components
import TwStatusIcon from '@/components/atoms/TwStatusIcon';
import TwTradingStatusIcon from '@/components/atoms/TwTradingStatusIcon';
import TwEntityIcon from '@/components/atoms/TwEntityIcon';
// constant
import { ENTITY_TYPE_VARIABLES, TRANSITION_FLG } from 'lib-tw-common';
import { entityNames } from '@/dictionaries/map.js';
import { getProcessNumber } from '@/utils/entity';

export default {
  name: 'TwTradingDataMapItem',
  components: {
    TwStatusIcon,
    TwTradingStatusIcon,
    TwEntityIcon,
  },
  props: {
    item: Object,
    type: String,
    index: Number,
    activeId: {
      type: String,
      default: '',
    },
    nextId: {
      type: String,
      default: '',
    },
    nextItems: {
      type: [Object, Array],
      default: () => []
    },
    activeColor: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    parentHeight: {
      type: Number,
      default: 0,
    },
    tradingFlow: {
      type: Object,
      default: () => {
        return { transitionFlag: TRANSITION_FLG.NG }
      },
    }
  },
  data() {
    return {
      // SVGカラー識別
      colors: {
        purple: { startColor: '#9B2AD0', stopColor: '#DB8EFF' },
        red: { startColor: '#EA5478', stopColor: '#FF98E2' },
        yellow: { startColor: '#FE7122', stopColor: '#FFD646' },
        blue: { startColor: '#5185C5', stopColor: '#3EC1FF' },
        green: { startColor: '#4EB100', stopColor: '#C8E416' },
        orange: { startColor: '#FF1203', stopColor: '#FDCA66' },
      },
      // 曲線の高さ
      lineHeight: 0,
      // ホバー中のカラー
      hoverColor: '',
    }
  },
  computed: {
    isActive() {
      // console.log(this.item.id, this.activeId)
      if (this.type === 'process') return `${this.index}_${this.item.id}` === this.activeId
      else return this.item.id === this.activeId
    },
    isNextId() {
      return this.nextId !== ''
    },
    isNextItems() {
      return this.nextItems.length > 0
    },
    getEntity() {
      if (this.type !== 'entity') return {}
      const entity = ENTITY_TYPE_VARIABLES.find((item) => item.label === this.item.name) || ''
      if (entity.code) return _.find(entityNames, item => item.code === entity.code) || {}
      else return {}
    },
    getProcessName() {
      if (this.item.transitionKeyInfo.processId === 'TPARB' || this.item.transitionKeyInfo.processId === 'TPRB') {
        return this.$t('Process.TPATPRB');
      }
      // if (this.item.transitionKeyInfo.processId.startsWith('FDCOR') || this.item.transitionKeyInfo.processId.startsWith('FDCOI')) {
      //   return this.$t(`Process.${this.item.transitionKeyInfo.processId.replace(/\d/g, '')}`) + ' ' + _.last(this.item.transitionKeyInfo.processId);
      // }
      return this.$t(`Process.${this.item.transitionKeyInfo.processId.replace(/\d/g, '')}`) + getProcessNumber(this.item.transitionKeyInfo.processId);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.lineHeight = this.getLineHeight()
    })
  },
  watch: {
    parentHeight() {
      this.lineHeight = this.getLineHeight()
      console.log(this.lineHeight)
    }
  },
  methods: {
    // クリック時の処理
    click() {
      this.$emit('click')
      if (this.type === 'process') this.link()
    },
    // 色の取得
    getColor(color) {
      return this.colors[color] || { startColor: '#fff', stopColor: '#fff' }
    },
    // 取引詳細 or プロセス詳細を別タブで開きます
    link() {
      if (!(this.item.transitionFlag || this.tradingFlow.transitionFlag)) return
      if (this.type === 'flow') {
        const id = this.item.id
        const flowId = id.substring(id.indexOf('/') + 1, id.length)
        this.linkToOtherWindow({
          name: 'ManageTrading',
          params: { flowId: flowId, tradingId: this.tradingFlow.transitionKeyInfo.tradingId },
          query: { flowId: flowId }
        });
      } else if (this.item.transitionKeyInfo.tradingId) {
        this.linkToOtherWindow({
          name: 'ManageTrading',
          params: { tradingId: this.item.transitionKeyInfo.tradingId },
        });
      } else if (this.item.transitionKeyInfo.entityId) {
        this.linkToOtherWindow({
          name: `Process${_.capitalize(this.item.transitionKeyInfo.processId.replace(/\d/g, ''))}`,
          params: { entityId: this.item.transitionKeyInfo.entityId, processSeq: this.item.transitionKeyInfo.processSeq, _processId: this.item.transitionKeyInfo.processId.toLowerCase() },
        });
      }
    },
    linkToOtherWindow({name, params, query}) {
      let resolvedRoute = this.$router.resolve({
        name: name,
        params: params,
        query: query,
      });
      window.open(resolvedRoute.href, '_blank');
    },
    // 曲線の高さを計算します
    getLineHeight() {
      if (this.type === 'organization') return
      const ref = this.$refs.item
      // console.log(ref, ref.offsetTop, this.parentHeight)
      // console.log(ref.offsetTop + (ref.clientHeight / 2))
      if (ref) {
        const adjust = this.type !== 'section' ? 2 : 0
        return ref.offsetTop + (ref.clientHeight / 2) - this.parentHeight - adjust
      } else return 0
    },
    mouseover() {
      this.hoverColor = 'white'
    },
    mouseleave() {
      this.hoverColor = ''
    },
  }
};
</script>

<style lang="scss" scoped>
// グラデーションカラー（※ スタイルガイドと微妙に違うためここで定義）
$purple: linear-gradient(81.09deg, #9B2AD0 -29.88%, #DB8EFF 111.7%);
$red: linear-gradient(79.61deg, #EA5478 -14.57%, #FF98E2 104.44%);
$yellow: linear-gradient(270deg, #FFD646 -11.21%, #FE7122 117.24%);
$blue: linear-gradient(236.52deg, #3EC1FF 8.58%, #5185C5 105.71%);
$green: linear-gradient(80.82deg, #4EB100 -17.87%, #C8E416 101.59%);
$orange: linear-gradient(237.29deg, #FDCA66 5.38%, #FF1203 90.56%);

.data_map_card {
  width: 200px;
  height: auto;
  min-height: 50px;
  margin: 0 0 12px;
  font-size: 12px;
  line-height: 18px;
  position: relative;
  cursor: pointer;
  .border {
    border-radius: 6px;
    background: $color_gray_400;
    padding: 1px;
    height: 100%;
    min-height: 50px;
  }
  .detail {
    border-radius: 5px;
    background: $color_white;
    padding: 8px 12px;
    height: 100%;
    min-height: 50px;
    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
    }
  }

  &.organization {
    .border {
      padding: 2px;
      position: relative;
    }
    .detail {
      border-radius: 4px;
    }
    .tri {
      position: absolute;
      top: calc(50% - 8px);
      left: -14px;
    }
    &.purple {
      .border {
        background: $purple;
      }
    }
    &.red {
      .border {
        background: $red;
      }
    }
    &.yellow {
      .border {
        background: $yellow;
      }
    }
    &.blue {
      .border {
        background: $blue;
      }
    }
    &.green {
      .border {
        background: $green;
      }
    }
    &.orange {
      .border {
        background: $orange;
      }
    }
    &.active {
      margin-left: 24px;
      .border {
        box-shadow: 0px 8px 8px rgba(170, 170, 204, 0.5);
        padding: 3px;
      }
      .detail {
        border-radius: 3px;
      }
    }
    .ellipse {
      position: absolute;
      display: flex;
      align-items: center;
      top: calc(50% - 8px);
      right: -14px;
    }
  }

  &._else {
    margin: 0 auto 10px;
    .border {
      padding: 2px;
    }
    .detail {
      border-radius: 4px;
    }
    &.active {
      // filter: drop-shadow(0px 8px 8px rgba(170, 170, 204, 0.5));
      z-index: 1;
      .border {
        box-shadow: 0px 8px 8px rgba(170, 170, 204, 0.5);
        // padding: 2px;
      }
      // .detail {
      //   border-radius: 4px;
      // }
      &.purple {
        .border {
          background: $purple;
        }
      }
      &.red {
        .border {
          background: $red;
        }
      }
      &.yellow {
        .border {
          background: $yellow;
        }
      }
      &.blue {
        .border {
          background: $blue;
        }
      }
      &.green {
        .border {
          background: $green;
        }
      }
      &.orange {
        .border {
          background: $orange;
        }
      }
    }
    &.entity {
      .detail {
        display: flex;
        align-items: center;
        padding: 8px;
        .name {
          display: flex;
          align-items: center;
        }
      }
    }
    .ellipse {
      position: absolute;
      top: calc(50% - 6px);
      left: -12px;
      display: flex;
      align-items: center;
      // z-index: 1;
      &._front {
        left: 200px;
      }
    }
    .line {
      position: absolute;
      bottom: calc(50% - 1px);
      left: -52px;
      transform: rotate(180deg);
      display: flex;
      align-items: center;
    }
  }

  &:hover {
    .border, .detail {
      background: $blue;
    }

    color: $color_white;
    .link svg path {
      stroke: $color_white;
    }
    &.purple {
      .border,
      .detail {
        background: $purple;
      }
    }
    &.red {
      .border,
      .detail {
        background: $red;
      }
    }
    &.yellow {
      .border,
      .detail {
        background: $yellow;
      }
    }
    &.blue {
      .border,
      .detail {
        background: $blue;
      }
    }
    &.green {
      .border,
      .detail {
        background: $green;
      }
    }
    &.orange {
      .border,
      .detail {
        background: $orange;
      }
    }
  }
}
</style>
<style lang="scss">
.data_map_card {
  .tw_entity_icon {
    width: 26px !important;
    height: 26px !important;
    background: transparent !important;
  }
}
</style>
